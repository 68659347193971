<template>
  <div class="row justify-center">
    <div class="col-9">
      <div id="meet" style="height: 100vh;">
      </div>
    </div>
    <div class="col-3 q-pa-md">
      <p
          class="text-h7"
          style="font-family: Lato; font-weight: 450"
      >
        This is your session! Join the session by clicking <b>Join meeting</b>. You can talk to the other person, share your screen, and come back to this session. Be careful not to share the link of this session with anyone else! It will automatically expire if the other party does not join within 5 minutes of the start time. If you accidentally exit the link, you can always rejoin (provided that the session hasn't ended)!
      </p>
      <p v-if="userType === 'requestor'"
          class="text-h7"
          style="font-family: Lato; font-weight: 450"
      >
        When satisfied with the session and ready to leave, use the following button to end it. It will end automatically after 2 hours.
      </p>
      <p v-else
         class="text-h7"
         style="font-family: Lato; font-weight: 450"
      >
        Only the student can end the session. When both of you are satisfied and ready to end, please ask them to end the session. It will end automatically after 2 hours.
      </p>
      <q-btn v-if="userType === 'requestor'" no-caps push label="End Session" style="background-color: #A8D0E6; border-radius: 20px; width: 12vw; white-space: nowrap; color: black; font-family: Montserrat; font-weight: 600; margin-top: 2vh;" label-color="grey-7" @click="finishSession" />


    </div>
  </div>
</template>
<script>
import firebase from "firebase";
import {getCurrentInstance} from "vue";
import {Dialog} from "quasar";

export default {
  name: "sessionConference",
  setup() {
    const internalInstance = getCurrentInstance()
    var jitsiAPI = null

    var userType = ""

    var URL = window.location.pathname.substr(9)

    return {
      jitsiAPI,
      URL,
      internalInstance,
      userType
    }
  },
  beforeMount() {
    const s = document.createElement("script")
    s.src = "https://meet.jit.si/external_api.js"
    s.type = 'text/javascript'
    document.head.appendChild(s)
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async finishSession() {
      await firebase.auth().onAuthStateChanged(async (user) => {
        let url = this.internalInstance.appContext.config.globalProperties.$backendURL
        let IDToken = await user.getIdToken(true)

        let response = await fetch(url + '/finishSession', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: IDToken,
            sessionURL: this.URL
          })
        })
        let result = await response.json()

        if (result.code !== "SUCCESSFUL") {
          Dialog.create({
            title: 'Cannot End Session',
            message: 'Something went wrong. Please try again. Error: ' + result.code,
            persistent: true
          }).onOk(async () => {
          }).onCancel(() => {
            // console.log('Cancel')
          }).onDismiss(() => {
            // console.log('I am triggered on both OK and Cancel')
          })
        } else {
          Dialog.create({
            title: 'Session Finished',
            message: 'Congrats! You have finished your learning experience! We hope it went well! You will now be redirected to the profile page.',
            persistent: true
          }).onOk(async () => {
            await this.$router.replace('/profile')
          }).onCancel(() => {
            // console.log('Cancel')
          }).onDismiss(() => {
            // console.log('I am triggered on both OK and Cancel')
          })
        }
      })
    }
  },
  async mounted() {
    await firebase.auth().onAuthStateChanged(async (user) => {
      let url = this.internalInstance.appContext.config.globalProperties.$backendURL
      let IDToken = await user.getIdToken(true)

      let response = await fetch(url + '/canUserJoinSession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: IDToken,
          sessionURL: this.URL
        })
      })
      let result = await response.json()

      if (!result.canJoin) {
        await this.$router.replace('/noSession')
      } else {
        const constdate = new Date()
        let time = new Date(result.sessionTime).getTime()
        let offset = constdate.getTimezoneOffset() * 60000
        let newDate = new Date(time - offset)

        if (!result.finished && newDate.getTime() - constdate.getTime() < 10000) {
          this.userType = result.userType
          if (result.userType === 'tutor') {
            let response = await fetch(url + '/tutorJoinedSession', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                token: IDToken,
                sessionURL: this.URL
              })
            })
            let result = await response.json()

            if (result.code !== "SUCCESSFUL") {
              Dialog.create({
                title: 'Cannot Join Session',
                message: 'Something went wrong. Please try again. Error: ' + result.code,
                persistent: true
              }).onOk(async () => {
              }).onCancel(() => {
                // console.log('Cancel')
              }).onDismiss(() => {
                // console.log('I am triggered on both OK and Cancel')
              })
              return
            }
          }
          else {
            let response = await fetch(url + '/creatorJoinedSession', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                token: IDToken,
                sessionURL: this.URL
              })
            })
            let result = await response.json()

            if (result.code !== "SUCCESSFUL") {
              Dialog.create({
                title: 'Cannot Join Session',
                message: 'Something went wrong. Please try again. Error: ' + result.code,
                persistent: true
              }).onOk(async () => {
                this.feedbackForm = false
              }).onCancel(() => {
                // console.log('Cancel')
              }).onDismiss(() => {
                // console.log('I am triggered on both OK and Cancel')
              })
              return
            }
          }

          const domain = 'meet.jit.si';
          const options = {
            roomName: this.URL,
            parentNode: document.querySelector('#meet'),
            interfaceConfigOverwrite: {
              SHOW_JITSI_WATERMARK: false,
              SHOW_WATERMARK_FOR_GUESTS: false,
              SHOW_CHROME_EXTENSION_BANNER: false
            },
          };
          await this.sleep(1000)

          // eslint-disable-next-line no-undef
          this.jitsiAPI = new JitsiMeetExternalAPI(domain, options);
        } else if (newDate.getTime() - constdate.getTime() < 86400000 && newDate.getTime() - constdate.getTime() > 10000) {
          await this.$router.replace('/earlySession')
        } else {
          await this.$router.replace('/noSession')
        }
      }
    })
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gentium+Basic:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
</style>